import styled from "styled-components";

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 72px -18px 96px -18px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
  }
`;

export const LinkItem = styled.a`
  display: inline-block;
  margin: 6px 18px;
  color: ${({ theme }) => theme.colors.links};
  font-size: 2.6rem;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  transition: 0.4s all ease;
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2rem;
    margin: 6px 12px;
  }
`;
