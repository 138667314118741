import styled from "styled-components";
import { GlobalWrapper, TextWrapper } from "shared/Styles/Wrappers";
import { Paragraph } from "components/Typography";

export const Container = styled(GlobalWrapper)`
  padding: 64px 0 64px 0;
  background-color: ${(props) =>
    props.purple ? props.theme.colors.purple : props.theme.colors.gray_light};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 48px 0 48px 0;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding: 48px 0 48px 0;
  }
`;

export const Wrapper = styled(TextWrapper)``;

export const TitleWrapper = styled.div`
  margin-bottom: 48px;
`;

export const Text = styled(Paragraph)`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  &:not(:last-child) {
    margin-bottom: 36px;
    display: block;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const TextBox = styled.div`
  margin-top: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 24px;
  }
`;
