import {graphql, useStaticQuery} from "gatsby";

export const useTeamPhotos = () => {
  const data = useStaticQuery(graphql`
      query {
          MariuszT: file(relativePath: { eq: "images/team/mariusz_trzeciak.png" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Grzegorz: file(relativePath: { eq: "images/team/grzesiek-3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Magda: file(relativePath: { eq: "images/team/magda-4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Furman: file(relativePath: { eq: "images/team/Furman.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Marzena: file(relativePath: { eq: "images/team/Marzena.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Bartek: file(relativePath: { eq: "images/team/Bartek.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Justyna: file(relativePath: { eq: "images/team/Justyna.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Daria: file(relativePath: { eq: "images/team/Daria.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          JustynaK: file(relativePath: { eq: "images/team/justyna_k.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Gabi: file(relativePath: { eq: "images/team/Gabi.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Kuba: file(relativePath: { eq: "images/team/Kuba.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          MariuszK: file(relativePath: { eq: "images/team/mariusz-kusiak.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Monika: file(relativePath: { eq: "images/team/Monika.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Patrycja: file(relativePath: { eq: "images/team/Patrycja.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Rafal: file(relativePath: { eq: "images/team/rafal-1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Krawczyk: file(relativePath: { eq: "images/team/Krawczyk.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Alex: file(relativePath: { eq: "images/team/Alex2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Michal: file(relativePath: { eq: "images/team/Michal.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Patryk: file(relativePath: { eq: "images/team/Patryk.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          PatrykSmall: file(relativePath: { eq: "images/team/PatrykSmall.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Adrian: file(relativePath: { eq: "images/team/Adrian.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Krystian: file(relativePath: { eq: "images/team/Krystian.png" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          WojtekS: file(relativePath: { eq: "images/team/wojtek_s.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Weronika: file(relativePath: { eq: "images/team/Weronika.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          Amadeusz: file(relativePath: { eq: "images/team/Amadeusz.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          PatrycjaM: file(relativePath: { eq: "images/team/Patrycja_m.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
           SylwiaBuksa: file(relativePath: { eq: "images/team/SylwiaBuksa.png" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }  
            RafalMiekina: file(relativePath: { eq: "images/team/RafalMiekina.png" }) {
              childImageSharp {
                  fluid(maxWidth: 810, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return data;
};
