import React from "react";
import { Container, Text } from "./styles";
import { TitleS } from "components/Typography";

export const CenteredTextBox = ({
  children,
  title,
  text,
  underline,
  size,
  mt,
  mb,
  titleTag,
}) => {
  return (
    <Container mt={mt} mb={mb} center>
      {title && (
        <TitleS as={titleTag} size={size} underline={underline}>
          {title}
        </TitleS>
      )}
      {text && <Text>{text}</Text>}
      {children && <Text>{children}</Text>}
    </Container>
  );
};
