import React from "react";

export const data_our_mission = {
  title: "Jesteś gotowy na niezapomnianą przygodę?",
  text: [
    <>
      Wieloletnie doświadczenie pozwoliło nam na zbadanie nawet najbardziej
      odległych galaktyk. Wiemy, co możemy zrobić, aby również Twoja podróż
      zakończyła się powodzeniem. To właśnie z Tobą chcemy polecieć w kosmos.
    </>,
    <>
      Nasz zespół to wykwalifikowani podróżnicy po Wszechświecie, którzy zawsze
      mogą na siebie liczyć. Marketing to nasza pasja, a nasze głowy są zawsze
      pełne pomysłów. Zostań naszym partnerem i sam zobacz, co możemy Ci
      zaoferować.
    </>,
    <>
      Z Twoimi chęciami i naszą pomocą możemy stworzyć nieziemski projekt, który
      pomoże rozwinąć się Twojej marce. Zgłoś się do nas, jeśli chcesz się
      przekonać, jak wygląda podbijanie kosmosu z InteractiveVision.
    </>,
  ],
};
