import styled, { css } from "styled-components";

const column = css`
  padding: 0 24px;
  width: 100%;
`;

export const Container = styled.div`
  margin: 72px 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin: 48px 0 24px 0;
  text-align: center;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const ColumnsContainerExtended = styled(ColumnsContainer)`
  width: 100%;
`;

export const ColumnLeft = styled.div`
  ${column}
  .wrapper:nth-child(3n + 1) > .container {
    width: 80%;
  }
  .wrapper:nth-child(3n + 2) > .container {
    width: 65%;
  }
  .wrapper:nth-child(3n) > .container {
    width: 75%;
  }
  .wrapper > .container {
    margin-left: auto;
    margin-right: 0;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    .wrapper:nth-child(3n + 1) > .container {
      width: 100%;
    }
    .wrapper:nth-child(3n + 2) > .container {
      width: 100%;
    }
    .wrapper:nth-child(3n) > .container {
      width: 100%;
    }
  }
`;
export const ColumnRight = styled.div`
  ${column}
  .wrapper:nth-child(3n +1) > .container {
    width: 65%;
  }
  .wrapper:nth-child(3n + 2) > .container {
    width: 75%;
  }
  .wrapper:nth-child(3n) > .container {
    width: 80%;
  }
  .wrapper > .container {
    margin-left: 0;
    margin-right: auto;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    .wrapper:nth-child(3n + 1) > .container {
      width: 100%;
    }
    .wrapper:nth-child(3n + 2) > .container {
      width: 100%;
    }
    .wrapper:nth-child(3n) > .container {
      width: 100%;
    }
  }
`;
