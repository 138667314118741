import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 50px auto 0;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  padding-right: 35px;
  width: 100%;
  margin: 0 auto;
`;

export const TextBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 30px;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  transform-origin: bottom right;
  height: 35px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 28px;
  }
`;

export const TextItem = styled.div`
  font-size: 1.6rem;
  margin-left: 0;
  white-space: nowrap;
  font-weight: 800;
  span {
    font-weight: 300;
    margin-left: 8px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.4rem;
    margin-left: 4px;
  }
`;
