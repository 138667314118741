import React from "react";
import { Container, Wrapper, TextBox, TitleWrapper, Text } from "./styles";
import { TitleL } from "components/Typography";

export const WhatAreWeDoing = ({ data, purple, color }) => {
  return (
    <Container purple={purple}>
      <Wrapper>
        <TitleWrapper>
          <TitleL color={color} underline>
            {data.title}
          </TitleL>
        </TitleWrapper>
        <TextBox>
          {data.text.map((item, i) => (
            <Text color={color} key={i}>
              {item}
            </Text>
          ))}
        </TextBox>
      </Wrapper>
    </Container>
  );
};
