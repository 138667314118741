import React from "react";
import { LinksWrapper, LinkItem } from "./styles";
import { TextWrapper } from "shared/Styles/Wrappers";

const WhatWeDoLinks = (props) => {
  return (
    <TextWrapper>
      <LinksWrapper>
        {props.links.map((link, i) => (
          <LinkItem key={i} href={link.href} title={link.title}>
            {link.title}
          </LinkItem>
        ))}
      </LinksWrapper>
    </TextWrapper>
  );
};

export { WhatWeDoLinks };
