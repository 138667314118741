import styled from "styled-components";
import { TextWrapper } from "shared/Styles/Wrappers";
import { Paragraph } from "components/Typography";
import { TitleS } from "components/Typography";

export const Container = styled(TextWrapper)`
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: 124px auto 124px auto;
  max-width: 800px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 72px auto;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    max-width: 700px;
    h2 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin: 64px auto;
    h2 {
      font-size: 1.8rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 48px auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    h2 {
      font-size: 1.6rem;
    }
  }
`;

export const Text = styled(Paragraph)`
  margin-top: 24px;
  display: block;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin-top: 24px;
  }
`;

export const CtaTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(TitleS)`
  font-size: 2rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.6rem;
  }
`;

export const ParagraphContainer = styled.div`
  padding-top: 18px;
  p {
    a {
      font-size: 1.6rem;
    }
  }
`;

export const TextSubheaderContainer = styled(TextWrapper)`
  margin: 64px auto;
`;
