import React from "react";

export const data_about_copy_write = {
  title: "Usiądź wygodnie, zapnij pasy, startujemy.",
  text: [
    <>
      Jak realizujemy naszą misję? Przede wszystkim nie ściemniamy - znamy nasze
      możliwości i wiemy, co możemy Ci zaproponować. Nie boimy się podejmować
      wyzwań, bo jesteśmy odkrywcami. Powiedz nam tylko, czego potrzebujesz.
    </>,
    <>
      Naszą załogę tworzą sami wybrańcy, znający się na swojej misji. Jesteśmy
      zgrani i możemy na siebie liczyć. Nie zbaczamy z kursu, a klient staje się
      z automatu częścią naszego zespołu. Chcemy byś był naszym partnerem.
    </>,
    <>
      <b>
        Nie bujamy w obłokach i nie opowiadamy bajek. Ty masz na siebie pomysł i
        fundusze na realizację tych planów, a my mamy wiedzę, doświadczenie i
        kompleksowe podejście do potrzeb naszych klientów. Po prostu - znamy się
        na naszej pracy. Wskakuj na pokład!
      </b>
    </>,
  ],
};
