import React from "react";
import SEO from "components/seo";
import {CenteredTextBox} from "components/TextBoxes";
import {WhatAreWeDoing} from "components/WhatAreWeDoing";
import {Team} from "sections/About/Team";
import {WhatWeDoLinks} from "sections/About/WhatWeDoLinks";
import {data_our_mission} from "../data/our_mission";
import {data_about_copy_write} from "../data/about_copywrite";
import {BigHero} from "components/BigHero";
import useHerosTypesData from "../shared/Hooks/PageHeaders/useHerosTypesData";
import {ABOUT} from "../shared/Types/heros";

const links = [
  {href: "/uslugi/copywriting", title: "content."},
  {href: "/uslugi/social-media", title: "social media."},
  {href: "/uslugi/kampanie-ads", title: "google ads."},
  {href: "/uslugi/branding", title: "branding."},
  {href: "/uslugi/web-design", title: "offline."},
  {href: "/uslugi/strony-www", title: "www."},
  {href: "/uslugi/media", title: "foto & video."},
  {href: "/uslugi/#marketing", title: "marketing."},
  {href: "/uslugi/sklepy-internetowe", title: "e-commerce."},
  {href: "/uslugi/grafika-3d", title: "wizualizacje."},
  {href: "/uslugi/aplikacje", title: "aplikacje."},
];

const About = () => {
  const data = useHerosTypesData(ABOUT);

  return (
    <>
      <SEO title={data.title} description={data.seo}/>
      <BigHero data={data}/>
      <div id="dlaczego-my">
        <CenteredTextBox
          titleTag="h2"
          title={"Zabłyśnij wśród innych rakiet, promów i satelitów"}
          text={
            "Zaangażowanie, kreatywność oraz profesjonalne i kompleksowe podejście do klienta to tylko jedne z wielu cech, które wyróżniają naszą agencję interaktywną. Do każdego podchodzimy indywidualnie, dzięki czemu potrafimy odpowiedzieć na nawet najbardziej kosmiczne oczekiwania!"
          }
        />
      </div>
      <WhatAreWeDoing data={data_our_mission}/>
      <div id="co-robimy">
        <CenteredTextBox
          title={"Nasz cel: Twój sukces"}
          titleTag="h3"
          text={
            <>Marketing nie ma dla nas tajemnic. Jesteśmy odkrywcami, którzy sprawią, że Twoja marka będzie się rozwijać. Co jeszcze możemy dla Ciebie zrobić? Wszystko, co jest związane z marketingiem internetowym. Z&nbsp;nami ta misja z&nbsp;pewnością okaże się sukcesem.</>
          }
        />
      </div>
      <WhatWeDoLinks links={links}/>
      {/*<div id="team">
        <Team/>
      </div>*/}
      <div id="standout">
        <WhatAreWeDoing color="#fff" purple data={data_about_copy_write}/>
      </div>
    </>
  );
};

export default About;
