import Image from "gatsby-image";
import React from "react";
import {useTeamPhotos} from "./useTeamPhotos";

export const useTeamData = () => {
  const images = useTeamPhotos();
  return [
    {
      name:     "Mariusz",
      position: "CEO and founder",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.MariuszT.childImageSharp.fluid}
                    alt="Mariusz"
                  />
                ),
    },
    {
      name:     "Patrycja",
      position: "Project Manager",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Patrycja.childImageSharp.fluid}
                    alt="Patrycja"
                  />
                ),
    },
    {
      name:     "Michał",
      position: "Project Manager",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Michal.childImageSharp.fluid}
                    alt="Michał"
                  />
                ),
    },
    {
      name:     "Magdalena",
      position: "Office Manager",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Magda.childImageSharp.fluid}
                    alt="Magdalena"
                  />
                ),
    },
    {
      name:     "Jakub",
      position: "Sales Manager",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Furman.childImageSharp.fluid}
                    alt="Jakub"
                  />
                ),
    },
    {
      name:     "Marzena",
      position: "Marketing Team Leader",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Marzena.childImageSharp.fluid}
                    alt="Marzena"
                  />
                ),
    },
    {
      name:     "Monika",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Monika.childImageSharp.fluid}
                    alt="Monika"
                  />
                ),
    },
    {
      name:     "Jakub",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Kuba.childImageSharp.fluid}
                    alt="Jakub"
                  />
                ),
    },
    {
      name:     "Gabriela",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Gabi.childImageSharp.fluid}
                    alt="Gabriela"
                  />
                ),
    },
    {
      name:     "Justyna",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.JustynaK.childImageSharp.fluid}
                    alt="Gabriela"
                  />
                ),
    },
    {
      name:     "Alex",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Alex.childImageSharp.fluid}
                    alt="Alex"
                  />
                ),
    },
    {
      name:     "Weronika",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Weronika.childImageSharp.fluid}
                    alt="Justyna"
                  />
                ),
    },
    {
      name:     "Patrycja",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.PatrycjaM.childImageSharp.fluid}
                    alt="Agnieszka"
                  />
                ),
    },
    {
      name:     "Amadeusz",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Amadeusz.childImageSharp.fluid}
                    alt="Justyna"
                  />
                ),
    },
    {
      name:     "Sylwia",
      position: "Marketing Specialist",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.SylwiaBuksa.childImageSharp.fluid}
                    alt="Sylwia Buksa"
                  />
                ),
    },
    {
      name:     "Justyna",
      position: "Copywriter",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Justyna.childImageSharp.fluid}
                    alt="Justyna"
                  />
                ),
    },
    {
      name:        "Patryk",
      position:    " Graphic Design Team Leader",
      image:       (
                     <Image
                       objectFit="contain"
                       fluid={images.Patryk.childImageSharp.fluid}
                       alt="Patryk"
                     />
                   ),
      image_small: (
                     <Image
                       objectFit="contain"
                       fluid={images.PatrykSmall.childImageSharp.fluid}
                       alt="Patryk"
                     />
                   ),
      about:
                   "Współzałożyciel i media owner serwisu newonce.net. Jeśli zastanawiacie się, kto wymyśla te wszystkie absurdalne opisy przy wrzutkach na niuansowym Facebooku - z reguły on.",
    },
    {
      name:     "Mariusz",
      position: "Graphic Designer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.MariuszK.childImageSharp.fluid}
                    alt="Mariusz"
                  />
                ),
    },
    {
      name:     "Daria",
      position: "Graphic Designer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Daria.childImageSharp.fluid}
                    alt="Daria"
                  />
                ),
    },
    {
      name:     "Bartek",
      position: "Graphic Designer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Bartek.childImageSharp.fluid}
                    alt="Bartek"
                  />
                ),
    },
    {
      name:     "Rafał",
      position: "Backend Developer Team Leader",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Krawczyk.childImageSharp.fluid}
                    alt="Rafał"
                  />
                ),
    },
    {
      name:     "Rafał",
      position: "Frontend Developer Team Leader",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Rafal.childImageSharp.fluid}
                    alt="Rafał"
                  />
                ),
    },
    {
      name:     "Adrian",
      position: "Backend Developer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Adrian.childImageSharp.fluid}
                    alt="Adrian"
                  />
                ),
    },
    {
      name:     "Rafał",
      position: "Backend Developer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.RafalMiekina.childImageSharp.fluid}
                    alt="Rafał Miękina"
                  />
                ),
    },
    {
      name:     "Krystian",
      position: "Frontend Developer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.Krystian.childImageSharp.fluid}
                    alt="Krystian"
                  />
                ),
    },
    {
      name:     "Wojtek",
      position: "Backend Developer",
      image:    (
                  <Image
                    objectFit="contain"
                    fluid={images.WojtekS.childImageSharp.fluid}
                    alt="Krystian"
                  />
                ),
    },
  ];
};
